<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Добавить администратора</h4>
          </div>
        </md-card-header>
        <ValidationObserver ref="addAdminForm" :slim="true">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Никнейм</label>
                <md-input v-model.trim="admin.nickname" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>ФИО</label>
                <md-input v-model.trim="admin.name" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>E-mail</label>
                <md-input v-model.trim="admin.email" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Телефон</label>
                <md-input
                  v-model="admin.phone"
                  v-mask="'+# (###) ###-##-##'"
                  md-counter="false"
                  maxlength="18"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Роль</label>
                <md-select v-model="admin.role" @focus="reset">
                  <md-option
                    v-for="(item, index) in roles"
                    :key="index"
                    :value="item"
                    placeholder="Роль"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required|min:8|max:20"
              vid="confirmation"
            >
              <md-field
                :class="{ error: errors.length }"
                :md-toggle-password="true"
              >
                <ValidationError :errors="errors" />
                <label>Пароль</label>
                <md-input
                  v-model.trim="admin.password"
                  type="password"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required|confirmed:confirmation"
            >
              <md-field
                :class="{ error: errors.length }"
                :md-toggle-password="true"
              >
                <ValidationError :errors="errors" />
                <label>Повторите пароль</label>
                <md-input
                  v-model.trim="admin.password_confirmation"
                  type="password"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <div class="buttons-wrap">
              <md-button class="md-raised md-success" @click="addNewAdmin">
                Сохранить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

const initAdmin = () => ({
  nickname: "",
  name: "",
  phone: "",
  email: "",
  role: null,
  password: "",
  password_confirmation: "",
});

export default {
  data() {
    return {
      admin: initAdmin(),
    };
  },

  computed: {
    ...mapState({
      roles: (state) => state.admins.roles,
    }),
  },

  async mounted() {
    await this.getRoles();
  },

  methods: {
    ...mapActions("admins", ["createAdmin", "getRoles"]),

    async addNewAdmin() {
      const isValid = await this.$refs.addAdminForm.validate();
      if (!isValid) {
        return;
      }

      this.admin.phone = this.admin.phone.replaceAll(/[-() ]/g, "");

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.createAdmin(this.admin);
      if (success) {
        this.admin = initAdmin();
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}
</style>
